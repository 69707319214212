// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-firstevent-js": () => import("./../../../src/pages/firstevent.js" /* webpackChunkName: "component---src-pages-firstevent-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-secondevent-js": () => import("./../../../src/pages/secondevent.js" /* webpackChunkName: "component---src-pages-secondevent-js" */)
}

